import React from "react"
import { Link } from "gatsby"
import MobileNav from "./mobileNav"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"


const Header = ({className}) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  // Single Image Data
  const imageData = desktop.childImageSharp.fluid

  return (
    <>
    <header className={className}>
        <div className="logo">
            <Link to="/" aria-label="logo"><Img fluid={imageData} /></Link>
        </div>
        <nav className="top">
          <div className="dropdown-container">
            <Link to="/about">About</Link>
            <div className="dropdown-content" style={{width: '300px'}}>
            <Link to="/about">English</Link>
              <Link to="/about-de">Deutsch</Link>
            </div>
          </div>
          <Link to="/concerts">Concerts</Link>
          <div className="dropdown-container">
            <Link to="/music">Music</Link>
            <div className="dropdown-content">
              <Link to="/music#videos">Videos</Link>
              <Link to="/music#cds">CDs</Link>
            </div>
          </div>
          <Link to="/photos">Photos</Link>
          <Link to="/contact">Contact</Link>
        </nav>
        <MobileNav />
        {className == "fullscreen-alt" && 
        <div className="content">
        <p><b>Benjamin Engeli</b> is one of the most versatile Swiss musicians of his generation. His career as a soloist, chamber musician and teacher has taken him to most European countries as well as to Australia, India, North and South America.</p>
        
        {/* <div className="button-container">
          <Link to="/katalog" class="button">Musik anhören</Link>
          <Link to="/schedule" class="button-alt">Konzert-Daten</Link>
        </div> */}

      </div>
        }
    </header>
    </>
  )
}

export default Header