import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

class mobileNav extends Component {
  state = {
    showNav: false,
  }

  openNav = () => {
    this.setState({ showNav: true })
  }

  closeNav = () => {
    this.setState({ showNav: false })
  }

  render() {
    const { showNav } = this.state

    return (
        <>
        <div className="mobile-nav">
            <StyledInput />
            <StyledLabel onClick={this.openNav}>
                <Line></Line>
                <Line></Line>
                <Line></Line>
            </StyledLabel>
        </div>   
        <MobileNav visible={showNav}>
            <a href="#" style={{textTransform: 'lowercase', position: 'absolute', top: '4px', right: '23px'}} onClick={this.closeNav}>&#10005;</a>

            <Link to="/about" activeStyle={{ color: "white" }} className="mobileNavActive" >About</Link>
            <Link to="/concerts" activeStyle={{ color: "white" }} className="mobileNavActive">Concerts</Link>
            <Link to="/music" activeStyle={{ color: "white" }} className="mobileNavActive">Music</Link>
            <Link to="/photos" activeStyle={{ color: "white" }} className="mobileNavActive">Photos</Link>
            <Link to="/contact" activeStyle={{ color: "white" }} className="mobileNavActive">Contact</Link>
        </MobileNav>
        </>
    )
  }
}

const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: #be9e5500; 
    width: 50px; 
    height: auto; 
    position: relative; 
    margin-left: auto; margin-right: auto;
    border-radius: 4px; 
    align-self: center;
`

const StyledInput = styled.input`
    display: none;
`

const Line = styled.span`
    margin: 4px auto;
    height: 4px; width: 80%; 
    background: #ffffff; border-radius: 1px;
    & :first-of-type {
        margin-top: 13px;
    }
    & :last-of-type {
        margin-bottom: 10px;
    }

`


const MobileNav = styled.div`
    z-index: 2;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #be9e55;
    text-transform: uppercase;
    transition: all 0.25s;

    opacity: ${props => (props.visible ? '1' : '0')};
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
    
    & > a {
        color: rgb(240,240,240, 0.4);
        font-size: 3em;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;

 
    }
`


export default mobileNav